document.addEventListener("DOMContentLoaded", function () {
  const roadmap = document.getElementById("roadmap");
  const content = document.getElementById("roadmap-year-now");
  const currentYearGroup = document.getElementById("current-year-group");
  const viewPortWidth = window.innerWidth;
  const targetWidth = currentYearGroup.scrollWidth;

  if (viewPortWidth < targetWidth) {
    roadmap.scrollTo({
      left: content.offsetLeft,
      behavior: "smooth",
    });
    return;
  }

  const delta = viewPortWidth - targetWidth;

  roadmap.scrollTo({
    left: content.offsetLeft - delta / 2,
    behavior: "smooth",
  });
});
